
.ant-layout-header, .ant-layout-sider, .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
  background: #181717;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: darken(#181717,2%);
}
.ant-layout-sider-trigger {
  background: lighten(#181717, 2%);
}

#root {
  width: 100%;
  height: 100%;
  > div {
    width: 100%;
    height: 100%;
    &.app_loading{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.app_header {
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 4;
}

.container {
  width: 100%;
  &.container__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
  }
}

.app_loading_nflex{
  width: 100%;
  height: 100%;
}

.app_view {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.profile__avatar {
  width: 160px;
  height: 160px;
  -webkit-border-radius: 160px;
  -moz-border-radius: 160px;
  border-radius: 160px;
  background-repeat: no-repeat;
  background-size: cover;
}

[for="upload_form_properties.copyright"], [for="edit_form_properties.copyright"] {
  font-weight: 600;
}

.grid-filter {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 12px 12px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 12px;
  max-width: 1280px;
}

.grid-filter.grid-2 {
  grid-template-columns: 1fr 1fr;
}

.grid-filter.grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-item {
  .grid--label {
    font-weight: 600;
    margin-bottom: 4px;
  }
}

.modal_media_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.media__wrapper {
  display: flex;
  flex-direction: column;
  flex-basis: calc(26% - 16px);
  width: 100%;
  padding: 4px;
  .media__item {
    flex: 1;
  }
  @media (max-width: 1280px) {
    margin-top: 4px;
    flex-basis: calc(30% - 16px);
  }
  @media (max-width: 1024px) {
    flex-basis: calc(34.5% - 16px);
  }
}
.media__item {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  position: relative;





  &.item__list {
    flex-direction: row;
    margin-top: 8px;
    .item__info {
      display: flex;
      flex-direction: column;
      padding: 12px;
      flex: 1;
      .title {
        font-weight: 900;
        padding: 0;
      }
      .title + .description {
        margin-top: 8px;
      }
    }
    .image {
      display: flex;
      flex-direction: row-reverse;
      overflow: auto;
      a.preview {
        //min-width: 240px;
        //max-width: 240px;
        width: 240px;
        min-width: 240px;

        display: block;
      }
      .item__actions {
        position: relative;
        padding: 4px;
        button {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  .image {
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    .bookmark_action {
      position: absolute;
      top: 4px;
      z-index: 999;
      left: 4px;
    }
    &:hover {
      .item__actions {
        button {
          transform: translateX(0);
          opacity: 1;
        }
      }
      a.preview {
        &:after {
          background-color: rgba(0, 0, 0, 0.3)
        }
      }
    }
    .item__actions {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      display: flex;
      margin-right: 4px;
      flex-direction: column;
      justify-content: center;
      height: 100%;


      button {
        transform: translateX(120%);
        opacity: 0;
        transition: all 0.2s ease 0s;
        &:nth-child(2) {
          transition: all 0.2s ease 0.1s;
        }
        &:nth-child(3) {
          transition: all 0.2s ease 0.2s;
        }
      }
      > button + button {
        margin-top: 4px;
      }
    }
  }

  a.preview {
    display: inline-block;
    width: 100%;
    padding-bottom: 56.6%;
    background-size: cover;
    background-position: center;
    font-size: 0;
    position: relative;
    background-color: #000000;

    .created_at {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 4px;
      margin: 0;
      width: 100%;
      font-size: 12px;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #FFF;

    }

    .image--usage {
      position: absolute;
      bottom: 4px;
      left: 0;
      z-index: 2;
      background: rgba(255, 0, 0, 1);
      width: 20px;
      height: 20px;
      border-radius: 4px;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      color: #FFF;
    }

    &.type_pdf {
      background-position: top center;
    }

    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      top: 0;
      left: 0;
      z-index: 1;
      transition: all 0.3s ease 0s;
    }

    > div {
      margin: 4px 0 0 4px;
      position: relative;
      z-index: 2;
    }
    &.can_bookmark {
      > div {
        margin-left: 24px;
        &.created_at {
          margin-left: 0;
        }
      }
    }
  }

  .title {
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.3;
  }
}